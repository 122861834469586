import Litepicker from 'litepicker';
import {formatDate} from './utils/date';
import Loader from './loader';
import initModal from './modal';
import {initQtyPickerOptions} from './quantity-picker';

document.addEventListener('DOMContentLoaded', function() {
  [...document.querySelectorAll('[data-booking]')]
    .forEach((node) => init(node));
});

function init(node) {
  const productId = node.dataset.id;
  const dates = node.querySelector('[data-booking-dates]');
  const locations = [...node.querySelectorAll('[data-bookable-location]')];
  const picker = createPicker(node);
  const srtDate = getStartDate(productId); // yyyy-mm-dd
  const endDate = getEndDate(productId); // yyyy-mm-dd
  const storedLocationId = window.sessionStorage.getItem(`${productId}:booking-location-id`);

  if (storedLocationId) {
    const storedLocation = node.querySelector('[data-bookable-location][value="' + storedLocationId + '"]');

    node.querySelector('[data-bookable-locations] span').innerText = storedLocation.dataset.name;
  }

  filterAvailabilities(node, srtDate, endDate, storedLocationId);
  dates.setAttribute('data-start', srtDate);
  dates.setAttribute('data-end', endDate);
  dates.querySelector('span').innerText = returnRangeAsString(srtDate, endDate);

  picker.on('selected', (first, second) => {
    handleDatesSelection(node, first, second);
  });
  picker.on('render', ui => {
    disablePastDates(ui);
    setCalendarWidth(node, ui);
  });
  locations.map(option => {
    option.addEventListener('change', handleLocationSelection);
  });
}

function handleLocationSelection(event) {
  const node = event.target.closest('[data-booking]');
  const locations = node.querySelector('[data-bookable-locations]');
  const dates = node.querySelector('[data-booking-dates]');
  const srtDate = dates.getAttribute('data-start');
  const endDate = dates.getAttribute('data-end');
  const productId = node.dataset.id;
  const locationId = event.target.hasAttribute('value') ? event.target.value : null;

  window.sessionStorage.setItem(`${productId}:booking-location-id`, locationId);
  locations.querySelector('span').innerText = event.target.dataset.name;
  filterAvailabilities(node, srtDate, endDate, locationId);
}

function handleDatesSelection(node, first, second) {
  const srtDate = formatDate(first.dateInstance); // yyyy-mm-dd
  const endDate = formatDate(second.dateInstance); // yyyy-mm-dd
  const dates = node.querySelector('[data-booking-dates]');
  const bookingNode = dates.closest('[data-booking]')
  const productId = bookingNode.dataset.id;

  window.sessionStorage.setItem(`${productId}:booking-date-start`, srtDate);
  window.sessionStorage.setItem(`${productId}:booking-date-end`, endDate);
  dates.setAttribute('data-start', srtDate);
  dates.setAttribute('data-end', endDate);
  dates.querySelector('span').innerText = returnRangeAsString(srtDate, endDate);
  filterAvailabilities(node, srtDate, endDate, getLocationId(node));
}

function handleTimeslotSelection(node) {
  const form = node.closest('form');
  const locationInput = form.elements['booking_details[product_bookable_location_id]'];
  const startDateInput = form.elements['booking_details[start_date]'];
  const endDateInput = form.elements['booking_details[end_date]'];
  const startTimeInput = form.elements['booking_details[start_time]'];
  const endTimeInput = form.elements['booking_details[end_time]'];

  // Sets selected values to hidden inputs
  [...node.querySelectorAll('[data-booking-timeslot]')].map((timeslot) => {
    timeslot.addEventListener('change', (event) => {
      const buyNow = form.querySelector('[data-buy-now]');
      const addToCart = form.querySelector('[data-add-to-cart]');
      const qtyPickers = document.querySelectorAll('[data-qty-picker]');

      addToCart.removeAttribute('disabled');
      if (buyNow) buyNow.removeAttribute('disabled');
      locationInput.value = event.target.dataset.location;
      startDateInput.value = event.target.dataset.startDate;
      endDateInput.value = event.target.dataset.endDate;
      startTimeInput.value = event.target.dataset.startTime;
      endTimeInput.value = event.target.dataset.endTime;
      [...qtyPickers].map(picker => {
        initQtyPickerOptions(picker, 1, event.target.dataset.available);
      });
    });
  });
}

function filterAvailabilities(node, startDate, endDate, locationId) {
  const locations = node.querySelector('[data-bookable-locations]');
  const addToCart = node.closest('form').querySelector('[data-add-to-cart]');
  const buyNow = node.closest('form').querySelector('[data-buy-now]');
  const slots = node.querySelector('[data-booking-timeslots]');
  const availabilitiesUrl = slots.dataset.bookingAvailabilitiesUrl;
  const productId = node.dataset.id;
  const endpoint = returnEndPoint({ availabilitiesUrl, productId, startDate, endDate, locationId });
  const loader = Loader({ target: slots, contextual: true });

  loader.on();
  locations.setAttribute('disabled', true);
  addToCart.setAttribute('disabled', true);
  if (buyNow) buyNow.setAttribute('disabled', true);
  slots.scrollTop = 0;

  fetchAvailabilities(endpoint)
    .then((text) => {
      loader.off();
      locations.removeAttribute('disabled');
      slots.innerHTML = text;
      handleTimeslotSelection(node);
      initModal();
    })
    .catch((_) => {
      loader.off();
      locations.removeAttribute('disabled');

      slots.innerHTML = `
        <div class="Notice Notice-alert sc-mt">
          Selection not found. Please choose another date or time.
        </div>
      `;
      handleTimeslotSelection(node);
    });
}

async function fetchAvailabilities(endpoint) {
  const response = await fetch(endpoint);
  if (response.ok) {
    const availabilities = await response.text();
    return availabilities;
  } else {
    throw new Error(response.statusText);
  }
}

function returnEndPoint({ availabilitiesUrl, productId, startDate, endDate, locationId }) {
  if (!availabilitiesUrl) {
    availabilitiesUrl = '/availabilities';
  }

  if (locationId) {
    return (
      `${availabilitiesUrl}?product_id=${productId}` +
      `&start_date=${startDate}` +
      `&end_date=${endDate}` +
      `&product_bookable_location_id=${locationId}`
    );
  } else {
    return (
      `${availabilitiesUrl}?product_id=${productId}` +
      `&start_date=${startDate}` +
      `&end_date=${endDate}`
    );
  }
}

function returnRangeAsString(startDate, endDate) {
  const dateFormat = {day: 'numeric', month: 'short'};
  const dateTime = new Intl.DateTimeFormat('en-US', dateFormat);

  return (
    dateTime.format(new Date(startDate)) + ' - ' +
    dateTime.format(new Date(endDate))
  );
}

function disablePastDates(ui) {
  const today = ui.querySelector('.is-today');
  const days = [...ui.querySelectorAll('.day-item')];

  if (today === null) return;
  for (let i = 0; i < days.length; i++) {
    if (days[i] === today) break;
    days[i].classList.add('is-disabled');
  }
}

function getStartDate(productId) {
  const storedStartDate = window.sessionStorage.getItem(`${productId}:booking-date-start`);
  return storedStartDate || formatDate(Date.now());
}

function getEndDate(productId) {
  const storedEndDate = window.sessionStorage.getItem(`${productId}:booking-date-end`);
  return storedEndDate || formatDate(Date.now() + 7 * 24 * 60 * 60 * 1000);
}

function getLocationId(container) {
  const locations = container.querySelector('[data-bookable-location]:checked');
  return locations ? locations.value : null;
}

function createPicker(node) {
  const container = node.querySelector('[data-booking-dates]');
  const icon = '<svg viewBox="0 0 100 100"><path d="M56.7,75.75 L95.4,37.05 C98.8,33.65 98.8,28.05 95.4,24.65 C92,21.25 86.4,21.25 83,24.65 L50.5,57.05 L18,24.55 C14.6,21.15 9,21.15 5.6,24.55 C3.9,26.25 3,28.55 3,30.75 C3,33.05 3.9,35.25 5.6,36.95 L44.3,75.75 C47.7,79.15 53.3,79.15 56.7,75.75 Z"></path></svg>';

  return new Litepicker({
    element: container,
    singleMode: false,
    buttonText: {previousMonth: icon, nextMonth: icon},
    showTooltip: false
  });
}

function setCalendarWidth(node, container) {
  const calendar = container.querySelector('.litepicker');
  if (calendar) calendar.style.width = node.clientWidth + 'px';
}
